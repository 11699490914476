$icons: aggregation arrows asc checkbox-checked-readonly checkbox-checked
  checkbox-indeterminate-readonly checkbox-indeterminate
  checkbox-unchecked-readonly checkbox-unchecked column columns contracted copy
  cut desc expanded eye-slash eye filter group indeterminate left loading menu
  minus none not-allowed paste pin pivot plus right small-left small-right
  small-up small-down tick cross tree-open tree-closed tree-indeterminate;

@each $icon in $icons {
  .ag-icon-#{$icon} {
    background-image: url('#{$icons-path}#{$icon}.svg');
  }
}
