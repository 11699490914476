/*
  socgen rules for both standard and dark themes
*/

.ag-theme-sg-bootstrap {
  .ag-header {
    border-left: 1px solid map-get($params, 'border-color');
    background: map-get($params, 'header-background-color');
    color: map-get($params, 'header-color');
  }

  .ag-header-row {
    border-bottom-width: $sgbs-table-header-border-bottom-width;
    border-bottom-color: map-get($params, 'primary-color');
  }

  .ag-header-cell {
    border-right: 1px solid map-get($params, 'border-color');
    border-top: 1px solid map-get($params, 'border-color') !important;
  }

  .ag-row-even:not(.ag-row-selected):not(.ag-row-hover) {
    background-color: map-get($params, 'even-row-background-color');
  }

  .ag-cell {
    border-right-color: map-get($params, 'border-color');
  }

  .ag-body-viewport {
    border-left: 1px solid map-get($params, 'border-color');
  }

  .ag-body {
    border-left: 1px solid map-get($params, 'border-color');
  }

  .ag-side-bar {
    border: 1px solid map-get($params, 'border-color');
    background-color: map-get($params, 'toolpanel-background-color');

    .ag-column-drag {
      background-size: 18px 15px;
    }
  }

  .ag-primary-cols-filter {
    &:focus {
      border-bottom-color: map-get($params, 'foreground-color');
    }
  }

  .ag-status-bar {
    border-top: 1px solid $border-color;
    background: map-get($params, 'background-color');
  }

  .ag-header-cell-menu-button .ag-icon-menu {
    background-image: url("#{map-get($params, 'icons-path')}filter_list.svg?color1=#{encodecolor(map-get($params, 'icon-color'))}");
  }

  .ag-icon-expanded {
    background-image: url("#{map-get($params, 'icons-path')}keyboard_arrow_down.svg?color1=#{encodecolor(map-get($params, 'icon-color'))}");
  }

  .ag-group-expanded .ag-icon-contracted:empty {
    background-image: url("#{map-get($params, 'icons-path')}keyboard_arrow_up.svg?color1=#{encodecolor(map-get($params, 'icon-color'))}");
  }

  .ag-theme-fresh .ag-menu,
  .ag-menu {
    background: map-get($params, 'menu-color');
  }

  .ag-theme-fresh .ag-menu .ag-menu-option-active {
    background: map-get($params, 'menu-active-color');
  }

  .ag-theme-fresh .ag-icon-small-right {
    opacity: 0.2;
    background-color: white;
  }

  .ag-primary-cols-header-panel {
    height: 45px;
    padding: 0;
    display: flex;
    align-items: center;

    & > div {
      padding: 0;
      margin: 0 0 0 8px;
    }

    .ag-primary-cols-filter-wrapper {
      margin: 0 8px;
    }

    .ag-primary-cols-filter {
      border: 1px solid $border-color;
      padding: 4px;
    }
  }

  .ag-side-buttons {
    border-left: 1px solid $border-color;
  }

  .ag-tool-panel-wrapper .ag-column-drop {
    min-height: 100px;
    .ag-icon,
    .ag-column-drop-title {
      height: 30px;
      line-height: 30px;
    }

    .ag-column-drop-cell {
      border-radius: 0;
      height: 24px !important;
      margin: 2px;
      padding: 0;

      .ag-column-drag {
        background-position-y: 5px !important;
        margin-left: 7px;
        margin-top: 0;
        width: 18px;
      }
      .ag-column-drop-cell-text {
        line-height: 26px;
        margin: 0;
      }
    }
  }
}

.ag-theme-sg-bootstrap-condensed {
  .ag-header-cell,
  .ag-header-group-cell {
    @include grid-cell(
      $header-height-condensed,
      #{map-get($params, 'cell-horizontal-padding-condensed')}
    );
  }

  .ag-cell {
    @include grid-cell(
      $row-height-condensed - 2,
      #{map-get($params, 'cell-horizontal-padding-condensed')}
    );
  }

  .ag-header-cell-menu-button .ag-icon-menu {
    display: block;
    height: $header-height-condensed;
  }
}
