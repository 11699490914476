@import "../../mixins/ag-theme-mixins";

@mixin ag-grid-theme($params) {
    $grid-size: map-get($params, "grid-size");
    $icon-size: map-get($params, "icon-size");
    $icon-color: map-get($params, "icon-color");
    $header-icon-size: map-get($params, "header-icon-size");
    $row-height: map-get($params, "row-height");
    $header-height: map-get($params, "header-height");
    $virtual-item-height: map-get($params, "virtual-item-height");
    $rich-select-item-height: map-get($params, "rich-select-item-height");
    $cell-horizontal-padding: map-get($params, "cell-horizontal-padding");

    $icons-path: map-get($params, "icons-path");

    $font-family: map-get($params, "font-family");
    $font-size: map-get($params, "font-size");
    $font-weight: map-get($params, "font-weight");

    $secondary-font-family: map-get($params, "secondary-font-family");
    $secondary-font-size: map-get($params, "secondary-font-size");
    $secondary-font-weight: map-get($params, "secondary-font-weight");

    $foreground-color: map-get($params, "foreground-color");
    $foreground-opacity: map-get($params, "foreground-opacity");

    $secondary-foreground-color-opacity: map-get($params, "secondary-foreground-color-opacity");
    $secondary-foreground-color: map-get($params, "secondary-foreground-color");

    $disabled-foreground-color-opacity: map-get($params, "disabled-foreground-color-opacity");
    $disabled-foreground-color: map-get($params, "disabled-foreground-color");
    $input-disabled-background-color: map-get($params, "input-disabled-background-color");

    $background-color: map-get($params, "background-color");
    $border-color: map-get($params, "border-color");
    $row-border-width: map-get($params, "row-border-width");
    $scroll-spacer-border: map-get($params, "scroll-spacer-border");

    $button-background-color: map-get($params, "button-background-color");

    $cell-data-changed-color: map-get($params, "cell-data-changed-color");

    $chip-background-color: map-get($params, "chip-background-color");

    $editor-background-color-color: map-get($params, "editor-background-color-color");

    $range-selection-background-color: map-get($params, "range-selection-background-color");
    $range-selection-highlight-color: map-get($params, "range-selection-highlight-color");
    $ag-range-selected-color-1: map-get($params, "ag-range-selected-color-1");
    $ag-range-selected-color-2: map-get($params, "ag-range-selected-color-2");
    $ag-range-selected-color-3: map-get($params, "ag-range-selected-color-3");
    $ag-range-selected-color-4: map-get($params, "ag-range-selected-color-4");

    $header-background-color: map-get($params, "header-background-color");
    $header-background-image: map-get($params, "header-background-image");
    $header-cell-hover-background-color: map-get($params, "header-cell-hover-background-color");
    $header-cell-moving-background-color: map-get($params, "header-cell-moving-background-color");
    $header-foreground-color: map-get($params, "header-foreground-color");
    $panel-background-color: map-get($params, "panel-background-color");
    $tool-panel-background-color: map-get($params, "tool-panel-background-color");
    $row-stub-background-color: map-get($params, "row-stub-background-color");

    $accent-color: map-get($params, "accent-color");
    $primary-color: map-get($params, "primary-color");
    $hover-color: map-get($params, "hover-color");
    $selected-color: map-get($params, "selected-color");

    $icon-opacity: map-get($params, "icon-opacity");
    $button-text-transform: map-get($params, "button-text-transform");
    $card-radius: map-get($params, "card-radius");
    $card-shadow: map-get($params, "card-shadow");
    $focused-textbox-border: map-get($params, "focused-textbox-border");

    $tooltip-background-color: map-get($params, "tooltip-background-color");
    $tooltip-foreground-color: map-get($params, "tooltip-foreground-color");
    $tooltip-border-radius: map-get($params, "tooltip-border-radius");
    $tooltip-padding: map-get($params, "tooltip-padding");
    $tooltip-border-width: map-get($params, "tooltip-border-width");
    $tooltip-border-style: map-get($params, "tooltip-border-style");
    $tooltip-border-color: map-get($params, "tooltip-border-color");

    $value-change-delta-up-color: map-get($params, "value-change-delta-up-color");
    $value-change-delta-down-color: map-get($params, "value-change-delta-down-color");
    $value-change-value-highlight-background-color: map-get($params, "value-change-value-highlight-background-color");
    $toolpanel-indent-size: map-get($params, "toolpanel-indent-size");
    $row-group-indent-size: map-get($params, "row-group-indent-size");
    $row-floating-background-color: map-get($params, "row-floating-background-color");

    ////////////////////////////////////////
    // Chinese wall of inline vars over
    ////////////////////////////////////////

    background-color: $background-color;
    color: $foreground-color;
    font-family: $font-family;
    font-weight: $font-weight;
    font-size: $font-size;

    *:focus {
        outline: none;
    }

    // indentation loop
    @for $i from 1 to 50 {
        .ag-ltr .ag-toolpanel-indent-#{$i} {
            padding-left: $i * $toolpanel-indent-size;
        }

        .ag-rtl .ag-toolpanel-indent-#{$i} {
            padding-right: $i * $toolpanel-indent-size;
        }

        .ag-ltr .ag-row-group-indent-#{$i} {
            padding-left: $i * $row-group-indent-size;
        }

        .ag-rtl .ag-row-group-indent-#{$i} {
            padding-right: $i * $row-group-indent-size;
        }
    }

    // we use rtl and ltr below, even though it's the same style, so it propertly
    .ag-ltr {
        .ag-row-group-leaf-indent {
            margin-left: $icon-size + $grid-size * 3;
        }
        .ag-cell {
            border-right: $cell-horizontal-border;
        }
        .ag-row, .ag-cell:not(.ag-cell-focus) {
            &.ag-cell-first-right-pinned {
                border-left: 1px solid $border-color;
            }
            &.ag-cell-last-left-pinned {
                border-right: 1px solid $border-color;
            }
        }
    }

    .ag-rtl {
        .ag-row-group-leaf-indent {
            margin-right: $icon-size + $grid-size * 3;
        }
        .ag-cell {
            border-left: $cell-horizontal-border;
        }
        .ag-row, .ag-cell:not(.ag-cell-focus) {
            &.ag-cell-first-right-pinned {
                border-left: 1px solid $border-color;
            }
            &.ag-cell-last-left-pinned {
                border-right: 1px solid $border-color;
            }
        }
    }

    // used by the animateShowChangedCellRenderer
    .ag-value-change-delta {
        padding-right: 2px;
    }

    .ag-value-change-delta-up {
        color: $value-change-delta-up-color;
    }

    .ag-value-change-delta-down {
        color: $value-change-delta-down-color;
    }

    .ag-value-change-value {
        background-color: transparent;
        border-radius: 1px;
        padding-left: 1px;
        padding-right: 1px;
        transition: background-color 1s;
    }

    .ag-value-change-value-highlight {
        background-color: $value-change-value-highlight-background-color;
        transition: background-color 0.1s;
    }

    ////////////////////////////////////////
    // Basic layout
    ////////////////////////////////////////
    .ag-header {
        @if $header-background-color {
            background-color: $header-background-color;
        }
        @if $header-background-image {
            background-image: $header-background-image;
        }

        color: $header-foreground-color;
        font-weight: $secondary-font-weight;
        font-size: $secondary-font-size;
        font-family: $secondary-font-family;
        border-bottom: 1px solid $border-color;
    }

    .ag-pinned-right-header {
        border-left: 1px solid $border-color;
    }

    .ag-pinned-left-header {
        border-right: 1px solid $border-color;
    }

    .ag-header-row {
        border: none;
    }

    .ag-row {
        @include row-border($border-color);
        border-width: 0;
        &:not(.ag-row-first) {
            border-width: $row-border-width 0 0;
        }
        &.ag-row-last {
            border-bottom-width: $row-border-width;
        }
    }

    @if map-get($params, "odd-row-background-color") {
        .ag-row-odd {
            background-color: map-get($params, "odd-row-background-color");
        }

        .ag-row-even {
            background-color: map-get($params, "background-color");
        }
    }

    .ag-horizontal-left-spacer {
        border-right: $scroll-spacer-border;
        &.ag-scroller-corner {
            border-right: none;
        }
    } 
    .ag-horizontal-right-spacer  {
        border-left: $scroll-spacer-border;
        &.ag-scroller-corner {
            border-left: none;
        }
    }

    .ag-row-hover {
        background-color: $hover-color;
    }

    .ag-numeric-cell {
        text-align: right;
    }

    .ag-header-cell-label {
        display: flex;
        height: 100%;

        & > span {
            float: left;
        }

        .ag-header-icon {
            background-position-y: $grid-size * 2.5;
            background-size: $header-icon-size $header-icon-size;
            height: 100%;
            margin: 0;
            margin-left: $grid-size;
            opacity: $foreground-opacity;
        }

        .ag-header-cell-text {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .ag-numeric-header {
        .ag-header-cell-label {
            flex-direction: row-reverse;
            float: right;

            & > span {
                float: right;
            }
        }

        .ag-header-cell-menu-button {
            float: left;
        }
    }

    .ag-header-group-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .ag-header-cell,
    .ag-header-group-cell {
        @include row-border($border-color);
        @include grid-cell($header-height, $cell-horizontal-padding);
        @if $header-cell-moving-background-color {
            &.ag-header-cell-moving {
                background-color: $header-cell-moving-background-color;
            }
        }
        border-width: 0;
        &:not(.ag-header-group-cell-no-group) {
            border-top-width: 1px;
        }
    }

    .ag-header-row:first-child {
        .ag-header-cell, .ag-header-group-cell {
            border-top-width: 0;
        }
    }

    .ag-header-group-cell:not(.ag-column-resizing) + .ag-header-group-cell,
    .ag-header-cell:not(.ag-column-resizing) + .ag-header-cell,
    .ag-header-group-cell:first-of-type,
    .ag-header-cell:first-of-type {
        @if ($header-background-color != $header-cell-hover-background-color) {
            &:hover, &.ag-column-resizing {
                background-color: $header-cell-hover-background-color;
            }
        }
    }

    .ag-cell {
        @include grid-cell($row-height - 2, $cell-horizontal-padding);

        // adjust for transparent border
        border: 1px solid transparent;
        padding-left: $cell-horizontal-padding - 1px;
        padding-right: $cell-horizontal-padding - 1px;
    }

    .ag-row-drag {
        float: left;
        height: 100%;
        width: $icon-size + $grid-size * 3;
        cursor: grab;
        .ag-icon-row-drag {
            display: block;
            width: 100%;
            height: 100%;
            @include icon-background($params, "grip");
            background-position-x: left;
            background-position-y: $grid-size / 2 + 2px;
        }
    }

    .ag-row-dragging, .ag-row-dragging .ag-row-drag {
        cursor: move;
    }

    .ag-rtl .ag-row-drag {
        float: right;
        .ag-icon-row-drag {
            background-position-x: right;
        }
    }

    .ag-column-drag {
        @include icon-background($params, "grip");

        background-position-x: left;
        background-position-y: $grid-size !important;
        height: 100%;
        min-width: $icon-size + $grid-size;
        cursor: grab;
    }

    .ag-row-dragging {
        opacity: 0.5;
        // very bad putting z-index in here, we have avoided it everywhere else in the grid,
        // however feel it's a minor issue - as it's only a temporary thing while row is dragging,
        // - it's unlikely user will have something on top of the grid while also moving a row in
        // the grid.
        z-index: 10000;
    }

    ////////////////////////////////////////
    // Navigation
    ////////////////////////////////////////

    .ag-ltr, .ag-rtl {
        .ag-has-focus .ag-cell-focus {
            border: 1px solid $primary-color;
            outline: initial;
        }
    }

    ////////////////////////////////////////
    // Resizing
    ////////////////////////////////////////
    .ag-header-cell-resize {
        width: $grid-size * 2;
    }

    ////////////////////////////////////////
    // Icons
    ////////////////////////////////////////
    $icons: aggregation arrows asc checkbox-checked-readonly checkbox-checked checkbox-indeterminate-readonly checkbox-indeterminate checkbox-unchecked-readonly checkbox-unchecked
        column columns contracted copy cut desc expanded eye-slash eye filter group indeterminate left loading menu minus none not-allowed paste pin pivot plus right small-left
        small-right small-up small-down tick cross tree-open tree-closed tree-indeterminate;

    @each $icon in $icons {
        .ag-icon-#{$icon} {
            @include icon-background($params, "#{$icon}");

            display: inline-block;
        }
    }

    .ag-header-cell-menu-button .ag-icon-menu {
        display: block;
        height: $header-height;
    }

    .ag-icon-checkbox-checked:empty {
        @include colorful-icon($icons-path, "checkbox-checked", $accent-color, map-get($params, "alt-icon-color"));
    }

    ////////////////////////////////////////
    // Context Menu
    ////////////////////////////////////////
    .ag-menu {
        @include card($params);

        padding: 0;
        z-index: 5;

        .ag-menu-list {
            cursor: default;
            margin-bottom: $grid-size;
            margin-top: $grid-size;
            width: 100%;
        }

        .ag-menu-option {
            line-height: $grid-size * 4;
            padding-left: $grid-size * 2;
            padding-right: $grid-size * 2;

            & > span {
                display: table-cell;
                vertical-align: middle;
            }
        }
        
        @if $hover-color {
            .ag-menu-option-active {
                background-color: $hover-color;
            }
        }

        .ag-menu-option-disabled {
            opacity: 0.5;
        }

        .ag-menu-option-icon {
            padding-left: $grid-size;
            padding-right: $grid-size;

            span {
                height: $icon-size;
                line-height: 0;
                margin-top: $grid-size;
            }
        }

        .ag-menu-option-shortcut {
            padding-left: $grid-size * 2;
        }

        .ag-menu-separator {
            margin-left: -$grid-size;

            & > span {
                background-image: url("data:image/svg+xml,%3Csvg%20width%3D'1'%20height%3D'#{$grid-size * 2}'%20viewBox%3D'0%200%201%20#{$grid-size * 2}'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cline%20x1%3D'0'%20y1%3D'#{$grid-size}'%20x2%3D'1'%20y2%3D'#{$grid-size}'%20stroke-width%3D'1'%20stroke%3D'#{encodecolor($border-color)}'%2F%3E%3C%2Fsvg%3E");
                height: $grid-size * 2;
            }
        }

        .ag-menu-option-popup-pointer {
            width: $icon-size + $grid-size * 2;
        }
    }

    ////////////////////////////////////////
    // Column Reordering
    ////////////////////////////////////////
    &.ag-dnd-ghost {
        @include card($params);

        border: 1px solid $border-color;
        color: $secondary-foreground-color;
        font-weight: $secondary-font-weight;
        font-size: $secondary-font-size;
        font-family: $secondary-font-family;
        height: $header-height !important;
        line-height: $header-height;
        margin: 0;
        padding: 0 $grid-size * 2;
        transform: translateY($grid-size * 2);
        z-index: 10000;

        span,
        div {
            float: left;
            height: 100%;
            margin: 0;
            padding: 0;
        }

        .ag-dnd-ghost-icon {
            margin-right: $grid-size;
            opacity: $foreground-opacity;
        }
    }

    $full-width-tabs: map-get($params, "full-width-tabs");

    ////////////////////////////////////////
    // Column Filter
    ////////////////////////////////////////
    .ag-tab-header {
        background: $panel-background-color;
        min-width: 220px;
        width: 100%;

        @if $full-width-tabs {
            display: table;
        }

        .ag-tab {
            @extend %tab !optional;

            border-bottom: 2px solid transparent;
            height: $grid-size * 4;
            text-align: center;
            vertical-align: middle;

            @if $full-width-tabs {
                display: table-cell;
            }

            &.ag-tab-selected {
                @extend %selected-tab !optional;

                $icons: filter columns menu;

                @each $icon in $icons {
                    .ag-icon-#{$icon} {
                        @include colorful-icon($icons-path, $icon, $primary-color, map-get($params, "alt-icon-color"));

                        display: inline-block;
                    }
                }
            }
        }
    }

    .ag-tab-body {
        padding: $grid-size 0;

        // string filter type
        .ag-filter-select {
            margin: $grid-size;
            width: calc(100% - #{$grid-size * 2});
        }

        .ag-menu-list {
            margin-bottom: 0;
            margin-top: 0;

            & > div:first-child > span {
                padding-top: 0;
            }

            & > div:last-child > span {
                padding-bottom: 0;
            }

            & > div:last-child > .ag-menu-option-popup-pointer {
                background-position-y: 0;
            }
        }
    }

    // string filter type
    .ag-filter-select {
        margin: $grid-size;
        width: calc(100% - #{$grid-size * 2});
    }

    ////////////////////////////////////////
    // Value Set Filter
    ////////////////////////////////////////
    .ag-filter {
        input[type="radio"] {
            margin: 0 3px 0 6px;
            width: 12px;
            height: 17px;
            vertical-align: top;
        }

        input[type="text"],
        input[type="date"] {
            @include textbox($params);

            padding-left: $grid-size;
        }

        label {
            display: block;
            padding-left: $grid-size;
        }

        .ag-set-filter-list {
            height: $virtual-item-height * 6.5;
            padding-top: $grid-size;
        }

        .ag-filter-header-container {
            height: $virtual-item-height;
        }

        .ag-filter-header-container:nth-child(2) {
            border-bottom: 1px solid $border-color;
        }

        .ag-filter-checkbox {
            float: left;
            height: $virtual-item-height;
            margin-right: $grid-size;
            padding-top: $grid-size / 2;
        }

        .ag-filter-value {
            height: $virtual-item-height;
            line-height: $grid-size * 3.5; // hate this
        }

        .ag-filter-apply-panel {
            display: flex;
            justify-content: flex-end;
            padding: $grid-size;
            padding-top: $grid-size * 2;

            button {
                @if map-get($params, "customize-buttons") {
                    @include regular-button($primary-color, $button-background-color, $button-text-transform);
                }
            }

            button + button {
                margin-left: $grid-size * 2;
            }
        }
    }

    ////////////////////////////////////////
    // Column selector
    ////////////////////////////////////////
    .ag-column-select-panel {
        .ag-column-tool-panel-column-group,
        .ag-column-tool-panel-column {
            height: $grid-size * 4;
            line-height: $grid-size * 4;
            margin-left: 0;

            span {
                float: left;
                height: 100%;
            }

            .ag-column-select-indent {
                width: $grid-size * 2;
            }

            .ag-column-select-checkbox,
            .ag-column-group-icons {
                margin-left: $grid-size;
                margin-right: $grid-size;
            }
        }

        .ag-primary-cols-list-panel {
            padding-top: $grid-size;
            > div {
                cursor: pointer;
            }
        }

        .ag-column-tool-panel-column.ag-toolpanel-add-group-indent {
            margin-left: $icon-size + $grid-size * 2;
        }
    }

    ////////////////////////////////////////
    // Tool panel
    ////////////////////////////////////////

    .ag-filter-filter {
        margin-bottom: $grid-size;
    }

    .ag-primary-cols-header-panel {
        border-bottom: 1px solid $border-color;
        height: $header-height;
        padding-top: $grid-size;

        > div {
            cursor: pointer;
            margin: 0 $grid-size;
            padding-top: $grid-size / 2;
        }

        .ag-filter-body {
            margin-left: $grid-size;
            margin-right: $grid-size;
        }
    }

    .ag-group-child-count::before {
        content: " ";
    }

    .ag-tool-panel-wrapper {
        border-right: 0;

        .ag-filter-panel {
            width: 100%;

            .ag-filter-toolpanel-instance {
                color: $secondary-foreground-color;
                font-weight: 600;
                flex: auto;
                flex-direction: column;
                flex-wrap: nowrap;
                display: flex;
                flex-flow: column nowrap;
                //border-bottom: 1px solid $border-color;
                //padding: 7px;

                .ag-filter-toolpanel-header {
                    padding: 5px 0 5px 5px;
                    > div {
                        margin: auto 0;
                    }
                }
            }
            .ag-filter-body-wrapper {
                padding-top: 5px;
            }

            .ag-filter-air {
                border: 1px solid $border-color;
                border-left: 0;
                border-right: 0;
                padding: $grid-size 0;
                //margin: 0 5px 0 5px;
            }
        }

        .ag-pivot-mode-panel {
            border-bottom: 1px solid $border-color;
            height: $header-height;
            line-height: $header-height;

            span {
                float: left;
                height: 100%;
            }

            .ag-pivot-mode-select {
                margin-left: $grid-size;

                .ag-checkbox-label {
                    margin-left: $grid-size;
                }
            }
        }

        .ag-column-select-panel {
            border-bottom: 1px solid $border-color;
            padding-bottom: $grid-size - 1; // absorb the bottom border;
            padding-top: 0;
        }

        $group-height: $grid-size * 5;

        .ag-column-drop {
            border-bottom: 1px solid $border-color;
            clear: both;
            overflow: auto;
            padding: $grid-size 0;
            padding-bottom: $grid-size * 2;

            .ag-icon {
                float: left;
                height: $group-height;
                margin: 0 $grid-size;
            }

            .ag-column-drop-title {
                clear: right;
                float: left;
                height: $group-height;
                line-height: $group-height;
                width: calc(100% - #{$icon-size + $grid-size * 2});
            }

            .ag-column-drop-empty-message {
                clear: both;
                color: $disabled-foreground-color;
                font-weight: $secondary-font-weight;
                font-size: $secondary-font-size;
                font-family: $secondary-font-family;
                line-height: $grid-size * 2;
                padding-left: $grid-size * 4;
                padding-right: $grid-size;
            }

            &:last-child {
                border-bottom: 0;
            }
        }
    }

    ////////////////////////////////////////
    // Sorting
    ////////////////////////////////////////
    .ag-filter-icon:empty {
        @include icon-background($params, "filter");
    }

    .ag-sort-ascending-icon:empty {
        @include icon-background($params, "asc");
    }

    .ag-sort-descending-icon:empty {
        @include icon-background($params, "desc");
    }

    .ag-sort-none-icon:empty {
        @include icon-background($params, "none");
    }

    .ag-numeric-header .ag-header-cell-label .ag-header-icon {
        margin-left: 0;
        margin-right: $grid-size;
    }

    ////////////////////////////////////////
    // Pagination
    ////////////////////////////////////////
    .ag-paging-panel {
        align-items: center;
        border-top: 1px solid $border-color;
        color: $secondary-foreground-color;
        display: flex;
        height: $header-height;
        justify-content: flex-end;
        padding: 0 $cell-horizontal-padding;

        & > span {
            margin-left: $grid-size * 4;
        }
    }

    @if map-get($params, "use-icons-for-pager-buttons") {
        $buttonIcons: (
            btFirst: first,
            btPrevious: previous,
            btLast: last,
            btNext: next
        );
        $rtlButtonIcons: (
            btFirst: last,
            btPrevious: next,
            btLast: first,
            btNext: previous
        );

        @each $ref, $icon in $buttonIcons {
            button[ref="#{$ref}"] {
                @include icon-background($params, $icon);
                @include hide-text;

                appearance: none;
                border: 0;
                opacity: $secondary-foreground-color-opacity;
                padding: 0;

                &[disabled] {
                    opacity: $disabled-foreground-color-opacity;
                }
            }
        }

        @each $ref, $icon in $rtlButtonIcons {
            .ag-rtl button[ref="#{$ref}"] {
                @include icon-background($params, $icon);
            }
        }
    }

    ////////////////////////////////////////
    // Selection
    ////////////////////////////////////////
    .ag-row-selected {
        background-color: $selected-color;
    }

    // we do not want to color the range color when the cell is also focused
    .ag-cell-range-selected:not(.ag-cell-focus) {
        background-color: $range-selection-background-color;
    }

    .ag-cell-range-selected-1:not(.ag-cell-focus) {
        background-color: $ag-range-selected-color-1;
    }

    .ag-cell-range-selected-2:not(.ag-cell-focus) {
        background-color: $ag-range-selected-color-2;
    }

    .ag-cell-range-selected-3:not(.ag-cell-focus) {
        background-color: $ag-range-selected-color-3;
    }

    .ag-cell-range-selected-4:not(.ag-cell-focus) {
        background-color: $ag-range-selected-color-4;
    }

    ////////////////////////////////////////
    // Editing
    ////////////////////////////////////////
    .ag-cell-inline-editing {
        padding: $cell-horizontal-padding;
        height: $row-height + $grid-size * 3;
        z-index: 2;
    }

    .ag-cell-inline-editing, .ag-popup-editor {
        @include card($params);

        background-color: $editor-background-color-color;

        input[type="text"],
        input[type="tel"],
        input[type="date"],
        input[type="datetime-local"] {
            @include textbox($params);
        }

        select {
            height: auto;
        }
    }

    .ag-popup-editor {
        z-index: 1;
        padding: 0;
        .ag-large-textarea textarea {
            @include textbox($params);

            height: auto;
            padding: $cell-horizontal-padding;
        }
    }

    ////////////////////////////////////////
    // Rich Select
    ////////////////////////////////////////
    .ag-rich-select {
        background-color: $editor-background-color-color;
        background-image: url("#{$icons-path}tree-open.svg?color1=#{encodecolor($icon-color)}");
        background-position-x: calc(100% - #{$grid-size});
        background-position-y: $grid-size * 2;
        background-repeat: no-repeat;

        .ag-rich-select-list {
            height: $row-height * 6.5; // intentional
        }

        .ag-rich-select-value {
            height: $row-height;
            line-height: $row-height;
            padding-left: $cell-horizontal-padding;
        }

        .ag-virtual-list-item {
            cursor: default;
            height: $rich-select-item-height;
            line-height: $rich-select-item-height;

            &:hover {
                background-color: $hover-color;
            }
        }

        .ag-rich-select-row {
            padding-left: $cell-horizontal-padding;
        }

        .ag-rich-select-row-selected {
            background-color: $selected-color;
        }
    }

    ////////////////////////////////////////
    // Floating filter
    ////////////////////////////////////////
    .ag-floating-filter-body {
        float: left;
        height: 100%;
        margin-right: 0;
        width: calc(100% - #{$icon-size + $grid-size * 2});

        input {
            @include textbox($params);
        }
    }

    .ag-floating-filter-full-body {
        input {
            @include textbox($params);
        }
    }

    .ag-floating-filter-button {
        float: right;
        line-height: $icon-size;
        margin-top: $grid-size * 2.5;

        button {
            appearance: none;
            background: transparent;
            border: 0;
            height: $icon-size;
            padding: 0;
            width: $icon-size;
        }
    }

    .ag-cell-label-container {
        height: 100%;
    }

    ////////////////////////////////////////
    // Tooltip
    ////////////////////////////////////////
    .ag-tooltip {
        background-color: $tooltip-background-color;
        color: $tooltip-foreground-color;
        border-radius: $tooltip-border-radius;
        padding: $tooltip-padding;
        border-width: $tooltip-border-width;
        border-style: $tooltip-border-style; 
        border-color: $tooltip-border-color;
        transition: opacity 1s;

        &.ag-tooltip-hiding {
            opacity: 0;
        }
    }
    ////////////////////////////////////////
    // Expanding headers
    ////////////////////////////////////////
    .ag-header-group-cell-label {
        height: 100%;

        span {
            float: left;
            height: 100%;
        }
    }

    ////////////////////////////////////////
    // Header checkbox selection
    ////////////////////////////////////////
    .ag-header-select-all {
        height: 100%;
        margin-right: $cell-horizontal-padding;

        span {
            height: 100%;
        }
    }

    .ag-header-select-all:not(.ag-hidden) + .ag-cell-label-container {
        float: left;
        width: calc(100% - #{$icon-size} - #{$cell-horizontal-padding});
    }

    ////////////////////////////////////////
    // Selection checkbox
    ////////////////////////////////////////
    .ag-selection-checkbox,
    .ag-group-checkbox:not(.ag-invisible),
    .ag-group-expanded:not(.ag-hidden),
    .ag-group-contracted:not(.ag-hidden) {
        ~ .ag-cell-value, + .ag-group-value {
            &:not(:empty) {
                display: inline-block;
                margin-left: $cell-horizontal-padding;
            }
        }
        ~ .ag-group-checkbox {
            margin-left: $cell-horizontal-padding;
        }
    }   

    .ag-selection-checkbox span {
        position: relative;
        top: $grid-size / 2;
    }

    .ag-group-expanded {
        .ag-icon-contracted:empty {
            @include icon-background($params, "tree-open");
        }
    }

    ////////////////////////////////////////
    // Column drop
    ////////////////////////////////////////
    .ag-column-drop-horizontal {
        background-color: $panel-background-color;
        height: $row-height;
        line-height: $grid-size * 4;
        padding-left: $cell-horizontal-padding;

        // nasty fix for inline-block approach with half-width.
        &.ag-width-half {
            margin-bottom: -5px;
        }

        span {
            float: left;
            height: 100%;
        }

        & > div:first-child {
            float: left;
            height: 100%;
        }

        .ag-icon-group,
        .ag-icon-pivot {
            margin-right: $cell-horizontal-padding;
        }

        .ag-right-arrow {
            @include icon-background($params, "right");
        }

        .ag-left-arrow {
            @include icon-background($params, "left");
        }

        .ag-left-arrow,
        .ag-right-arrow {
            @include hide-text;

            height: 100%;
            margin: 0 $grid-size;
            opacity: $secondary-foreground-color-opacity;
        }

        .ag-column-drop-empty-message {
            height: 100%;
            line-height: $row-height;
            opacity: $disabled-foreground-color-opacity;
        }
    }

    .ag-column-drop-cell {
        background: $chip-background-color;
        border-radius: $grid-size * 4;
        height: $grid-size * 4 !important;
        margin-top: $grid-size;
        padding: 0 $grid-size / 2;

        .ag-column-drop-cell-text {
            height: 100%;
            line-height: $grid-size * 4;
            margin: 0 $grid-size;
        }

        .ag-column-drop-cell-button {
            @include icon-background($params, "cancel");
            @include hide-text;
            min-width: $grid-size * 4;

            height: 100%;
            margin: 0 $grid-size / 2;
            opacity: $secondary-foreground-color-opacity;

            &:hover {
                opacity: $foreground-opacity;
            }
        }

        .ag-column-drag {
            margin-left: $grid-size * 2;
            margin-top: $grid-size / 2;
            width: $icon-size;
        }
    }

    .ag-select-agg-func-popup {
        @include card($params);

        background: $background-color;
        height: $grid-size * 5 * 3.5;
        padding: 0;

        .ag-virtual-list-item {
            cursor: default;
            line-height: $grid-size * 5;
            padding-left: $grid-size * 2;
        }

        .ag-virtual-list-item:hover {
            background-color: map-get($params, "menu-option-active-color");
        }
    }

    .ag-set-filter-list,
    .ag-menu-column-select-wrapper {
        width: auto;
    }

    .ag-column-drop-vertical > .ag-column-drop-cell {
        float: left;
        margin-bottom: $grid-size;
        margin-left: $grid-size;
        margin-top: 0;
    }

    // cell data changed
    .ag-cell-data-changed {
        background-color: $value-change-value-highlight-background-color !important;
    }

    .ag-cell-data-changed-animation {
        background-color: transparent;
        transition: background-color 1s;
    }

    .ag-row-stub {
        background-color: $row-stub-background-color;
    }

    .ag-stub-cell {
        padding-left: $cell-horizontal-padding;
        padding-top: $grid-size;

        .ag-loading-icon {
            float: left;
            height: 100%;
        }

        .ag-loading-text {
            float: left;
            height: 100%;
            margin-left: $grid-size;
            margin-top: $grid-size;
        }
    }

    .ag-floating-top {
        border-bottom: 1px solid $border-color;
    }
    .ag-floating-bottom {
        border-top: 1px solid $border-color;
    }

    .ag-floating-top, .ag-floating-bottom {
        background-color: $row-floating-background-color;

        .ag-row {
            background-color: $row-floating-background-color;
        }
    }

    ////////////////////////////////////////
    // Right-To-Left
    ////////////////////////////////////////
    .ag-rtl {
        text-align: right;

        .ag-numeric-cell {
            text-align: left;
        }

        .ag-header-cell-menu-button {
            float: left;
        }

        .ag-header-cell-label {
            & > span {
                float: right;
            }

            .ag-header-icon {
                margin-top: 2px;
            }
        }

        .ag-numeric-header {
            .ag-header-cell-menu-button {
                float: right;
            }

            .ag-header-cell-label {
                float: left;

                & > span {
                    float: left;
                }
            }
        }

        .ag-tool-panel-wrapper {
            .ag-pivot-mode-panel {
                span {
                    float: right;
                }

                .ag-pivot-mode-select {
                    margin-right: $grid-size;

                    .ag-checkbox-label {
                        margin-right: $grid-size;
                    }
                }
            }

            .ag-column-drop {
                .ag-icon {
                    float: right;
                }

                .ag-column-drop-title {
                    clear: left;
                    float: right;
                }

                .ag-column-drop-empty-message {
                    padding-left: $grid-size;
                    padding-right: $grid-size * 4;
                }
            }
        }

        .ag-filter-checkbox {
            float: right;
            margin-left: $grid-size;
        }

        .ag-column-select-panel {
            .ag-column-tool-panel-column-group,
            .ag-column-tool-panel-column {
                span {
                    float: right;
                }

                .ag-column-select-checkbox,
                .ag-column-group-icons {
                    margin-left: $grid-size;
                    margin-right: $grid-size;
                }
            }

            .ag-column-tool-panel-column.ag-toolpanel-add-group-indent {
                margin-left: 0;
                margin-right: $icon-size + $grid-size * 2;
            }
        }

        .ag-icon-tree-closed {
            @include icon-background($params, "contracted");
        }

        .ag-header-group-cell-label {
            height: 100%;

            span {
                float: right;
                height: 100%;
            }
        }

        .ag-header-select-all:not(.ag-hidden) + .ag-cell-label-container {
            float: right;
        }

        .ag-header-select-all {
            margin-left: $cell-horizontal-padding;
            margin-right: 0;
        }

        .ag-selection-checkbox,
        .ag-group-checkbox,
        .ag-group-expanded,
        .ag-group-contracted {
            + .ag-cell-value, + .ag-group-value {
                &:not(:empty) {
                    display: inline-block;
                    margin-right: $cell-horizontal-padding;
                    margin-left: initial;
                }
            }
            + .ag-group-checkbox {
                margin-right: $cell-horizontal-padding;
                margin-left: initial;
            }
        }

        .ag-column-drop-horizontal {
            padding-right: $cell-horizontal-padding;

            span {
                float: right;
            }

            & > div:first-child {
                float: right;
            }

            .ag-icon-group,
            .ag-icon-pivot {
                margin-left: $cell-horizontal-padding;
                margin-right: 0;
            }

            .ag-right-arrow {
                @include icon-background($params, "right");

                height: 100%;
            }

            .ag-left-arrow {
                @include icon-background($params, "left");

                height: 100%;
            }
        }

        .ag-floating-filter-body {
            float: right;
            margin-left: 0;
        }

        .ag-floating-filter-button {
            float: left;
        }

        .ag-header .ag-header-cell-resize::after {
            border-left: 1px solid $border-color;
            border-right: 0;
        }

        .ag-column-drag {
            background-position-x: right;
        }
    }

    .ag-status-bar {
        background: $background-color;
        border: 1px solid $border-color;
        border-top: 0;
        color: $disabled-foreground-color;
        font-weight: $secondary-font-weight;
        font-size: $secondary-font-size;
        font-family: $secondary-font-family;
        padding-right: $grid-size * 4;
        padding-left: $grid-size * 4;
    }

    .ag-name-value-value {
        color: $foreground-color;
    }

    .ag-status-bar-center {
        text-align: center;
    }

    .ag-name-value {
        margin-left: $grid-size;
        margin-right: $grid-size;
        padding-top: $grid-size * 2;
        padding-bottom: $grid-size * 2;
    }

    .ag-details-row {
        padding: $grid-size * 5;
    }

    .ag-overlay-loading-wrapper {
        background-color: rgba(255, 255, 255, 0.5);
    }

    .ag-overlay-loading-center {
        @include card($params);
    }

    .ag-side-bar {
        background-color: $tool-panel-background-color;
        border-right: 1px solid $border-color;
        border-top: 1px solid $border-color;
        position: relative;

        .ag-side-buttons {
            padding-top: $grid-size * 4;
            background: $background-color;
            border-bottom: 1px solid $border-color;
            position: relative;

            .ag-side-button {
                button {
                    background: transparent;
                    border: 0;
                    color: $foreground-color;
                    padding: $grid-size * 2 0 $grid-size * 2 0;
                    width: 100%;
                    margin: 0;
                    min-height: $grid-size * 18;
                    border-width: 1px 0 1px 0;
                    border-style: solid;
                    border-color: transparent;
                    background-position-y: $grid-size;
                    background-position-x: center;
                    background-repeat: no-repeat;

                }
            }

            .ag-selected {
              button {
                background-color: $tool-panel-background-color;
                margin-left: -1px;
                padding-left: 1px;
                width: calc(100% + 1px);
                border-color: $border-color;
              }
            }

        }

        .ag-panel-container {
            border-right: 1px solid $border-color;
        }

        &.full-width .ag-panel-container {
            border-right: 0;
        }

        .ag-column-drop {
            min-height: 50px;
        }
    }

    .ag-rtl .ag-side-bar {
        .ag-panel-container {
            border-left: 1px solid $border-color;
            border-right: 0;
        }

        &.full-width .ag-panel-container {
            border-left: 0;
        }
    }

    .ag-primary-cols-filter {
        @include textbox($params);
        width: 100%;
    }

    .ag-primary-cols-filter-wrapper {
        margin-left: $grid-size;
        margin-right: $grid-size;
    }

    $exported: (
        autoSizePadding: $grid-size * 3,
        headerHeight: $header-height,
        groupPaddingSize: $grid-size * 3 + $icon-size,
        footerPaddingAddition: $grid-size * 4,
        virtualItemHeight: $virtual-item-height,
        aggFuncPopupHeight: $virtual-item-height * 3.5,
        checkboxIndentWidth: $grid-size + $icon-size,
        leafNodePadding: $grid-size * 3,
        rowHeight: $row-height,
        gridSize: $grid-size,
        iconSize: $icon-size
    );

    $json-list: ();

    @each $name, $value in $exported {
        $json-list: append($json-list, unquote('"#{$name}": "#{$value}"'), comma);
    }

    .sass-variables::after {
        content: "{ #{$json-list} }";
        display: none;
    }
}
